<template>
  <span class="loader" />
</template>

<script>
  export default {
  }
</script>

<style >
  .loader 
  {
    display: inline-block;
    border: 1vh solid #DCAB2C;
    border-top: 1vh solid #DCAB2C; 
    border-bottom: 1vh solid lightgrey;
    border-left: 1vh solid lightgrey;
    border-right: 1vh solid lightgrey;
    border-radius: 50%;
    width: 10vh;
    height: 10vh;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader-little
  {
    width: 5vh;
    height: 5vh;
  }
</style>
